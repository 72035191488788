:root {
  --thumb-width: 51px;
  /* Default value, adjust as needed */
  --thumb-position: 0;
  /* Default position at 0% */
  --background-light: #fafdfd;
  --background-dark: #121212;
  --link-color-light: #74B9F9;
  --link-color-dark: #383FEC;
  --primary-color-light: #383FEC;
  --primary-color-dark: #74B9F9;
  --text-color-light: #000;
  /* Text color for light theme */
  --text-color-dark: #FFF;
  /* Text color for dark theme */
  --text-color-light-primary: #FFF;
  --secondary-text-light: #555;
  --secondary-text-dark: #fafafa;
  --header-bg-light: #FAFAFF;
  /* Header background color for light theme */
  --header-bg-dark: #26273A;
  /* Header background color for dark theme */
  --btn-bg-light: #f3f4ff;
  --btn-bg-dark: #333333;
  --plus-light: #C1C1C1;
  --plus-dark: #2A2D33;
  --toggle-btn-light: #FFF;
  --toggle-btn-dark: rgba(0, 0, 0, 0);
  --box-border-light: #EFEFEF;
  --box-border-dark: #2A2D33;
  --filler-light: #ebebeb;
  --filler-dark: var(--header-bg-dark);
  --strike-bg-light: #f5f5f5;
  --strike-bg-dark: #1B1D21;
  --secondary-color: #f3f4ff;
  --quick-view-footer-light: #F5F5F5;
  --quick-view-footer-dark: var(var(--header-bg));
  --buy-sell-btn-text-dark: #FFF;
  --buy-sell-btn-text-light: #000;
}



#root {
  min-height: 100vh;
}

.light {
  background: #fafdfd;
  --background: var(--background-light);
  --card-background: #FFF;
  --primary: var(--primary-color-light);
  --link-color: var(--link-color-light);
  --text-color: var(--text-color-light);
  --secondary-text: var(--secondary-text-light);
  --header-bg: var(--header-bg-light);
  --btn-bg: var(--btn-bg-light);
  --plus: var(--plus-light);
  --toggle-btn: var(--toggle-btn-light);
  --box-border: var(--box-border-light);
  --filler: var(--filler-light);
  --strike-bg: var(--strike-bg-light);
  --quick-view-footer-bg: var(--quick-view-footer-light);

}

.dark {
  background: #121212;
  --background: var(--background-dark);
  --card-background: #1B1D21;
  color: #FFF !important;
  --text-color: var(--text-color-dark);
  --link-color: var(--link-color-dark);
  --primary: var(--primary-color-dark);
  --secondary-text: var(--secondary-text-dark);
  --header-bg: var(--header-bg-dark);
  --btn-bg: var(--btn-bg-dark);
  --plus: var(--plus-dark);
  --toggle-btn: var(--toggle-btn-dark);
  --box-border: var(--box-border-dark);
  --filler: var(--filler-dark);
  --strike-bg: var(--strike-bg-dark);
  --quick-view-footer-bg: var(--quick-view-footer-dark);
  --buy-sell-btn-text: var(--buy-sell-btn-text-dark);
}


.inner {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 1rem;
  background: white;
  z-index: 1000000000;
}

.wrapper {
  position: absolute;
  display: flex;
  background: white;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}


@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.tooltip-question-mark {
  cursor: pointer;
}

a {
  color: var(--link-color);
}

#disabled-live-tooltip {
  display: flex;
  align-items: center;
  height: 37.60px !important;
}


/* Toastify custom css */
.Toastify__toast-icon {
  align-self: flex-start;
}

.Toastify__toast {
  font-family: "Satoshi-Variable", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  position: absolute;
}

/* Custom styles for success toast */
.custom-success-toast {
  background-color: #CDECDA;
  height: 85px;
  width: 433px;
  right: 125px;
  top: 62px;
}

/* Custom styles for warning toast */
.custom-warning-toast {
  background-color: #F9ECD3;
  height: 85px;
  width: 433px;
  right: 125px;
  top: 62px;
}

/* Custom styles for error toast */
.custom-error-toast {
  background-color: #FFD4C9;
  height: 85px;
  width: 433px;
  right: 125px;
  top: 62px;
}

/* Custom styles for info toast */
.custom-info-toast {
  background-color: rgba(193, 193, 193, 0.22);
  height: 85px;
  width: 433px;
  right: 125px;
  top: 62px;
}

.confirm-popup-toast {
  width: 428px;
  min-height: 54px;
  padding: initial;
  margin: 0;
  bottom: 138px;
  right: 46px;
  box-shadow: none;
  border-radius: 8px;
}

.confirm-popup-toast-active {
  width: 428px;
  min-height: 54px;
  padding: initial;
  margin: 0;
  bottom: 350px !important;
  right: 46px;
  box-shadow: none;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.confirm-popup-toast-active .Toastify__toast-body {
  padding: 0px 0px 0px 12px;
}

.quick-view-toast {
  margin: auto auto 250px auto;
  right: 0 !important;

}


.pos-toast {
  right: 0 !important;
}

.confirm-popup-toast-position {
  width: 428px;
  min-height: 54px;
  padding: initial;
  margin: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, -400%);
  box-shadow: none;
  border-radius: 8px;
}




.confirm-popup-toast .Toastify__toast-body {
  padding: 0px 0px 0px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.confirm-popup-toast-position .Toastify__toast-body {
  padding: 0px 0px 0px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.announcement-banner-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 130vh;
  position: fixed;
  z-index: 99;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 99;

}

.announcement-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  top: 9rem;
  max-width: 100%;
}

.banner-image {
  width: 70%;
  height: auto;
}

.banner-close-btn {
  position: absolute;
  top: 0px;
  right: 16%;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.play-app-store-btn-container {
  top: 77%;
  left: 18%;
}

@media (max-width: 768px) {
  .announcement-banner-container {
    height: 100vh;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 999;
    /* z-index: 9999; */
  }

  .announcement-banner {
    position: relative;
    top: auto;
    margin-bottom: 0;
  }

  .play-app-store-btn-container {
    top: 28%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*  ================================ Header CSS =================================== */
.app-logo {
  /* height: 40px;
  width: 40px; */
}

.container-fluid {
  --bs-gutter-x: 0px;
}


.navbar-nav {
  font-size: 0.875rem;
}

.nav-link {
  color: var(--text-color);
}

.inactive {
  color: var(--text-color);
  text-decoration: none !important;
  font-weight: 500;
}

.active {
  color: var(--primary);
  text-decoration: none !important;
  font-weight: 500;
}

.header {
  grid-column: 1 / span 16;
  height: 60px;
  background: var(--card-background);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

}

.symbol-name {
  font-size: 0.625rem;
  color: var(--secondary-text);

}

.ch-chp {
  font-size: 0.75rem;
}

.nifty,
.banknifty,
.indiavix {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.symbol-containers {
  width: 175px;
}

.client-id {
  color: var(--text-color);
  ;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.broker-name {
  color: var(--text-color);
  ;
  font-size: 10px;
  font-weight: 500;
  line-height: 0px;
}

.user-profile-dropdown-menu {
  border-radius: 4px;
  border: 1px solid #989898;
  --bs-dropdown-min-width: 91px;
}

.header-dropdown-menu[data-bs-popper] {
  position: absolute;
  width: 188px;
  left: -150px;
  top: 30px;
  display: flex;
  flex-direction: column;
  padding-left: 17px;
}

.trade-mode {
  height: 16px;
  padding-top: 12px;
  font-size: 13px;
  font-weight: 700;
  color: var(--text-color);
}

.trade-mode-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkbox {
  margin-left: 5px;
}

.dropdown-logout {
  position: relative;
  cursor: pointer;
  width: 43px;
  height: 19px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
  ;
  top: 22px;
}

.user-profile-dropdown-menu .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.user-profile-dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -60px;
  margin-top: var(--bs-dropdown-spacer);
}

.drp {
  left: -20px !important;
}




/* .quick-view-button {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 20vh;
  right: 5vw;
  z-index: 99999;
  background: #F3F4FF;
  border: none;
  height: 32px;
  width: 108px;
  color: #383FEC;
} */

.quickview-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickview-modal-content {
  width: 100%;
  max-width: 100%;
  background-color: var(--card-background);
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 999999999999999999;

}


.quick-view-close-btn {
  color: #989898;
  background: none;
  font-size: 20px;
  font-weight: bolder;
  border: none;
}

.quick-view-pnl {
  font-size: 14px;
  font-weight: 500;
}

.expanded-quickview-content {
  height: 280px;
  padding: 12px 0px;
  max-width: 1392px;
  margin: auto;
}

.search-container {
  width: 253px;
  height: 38px;
  width: 253px;
  height: 38px;
  color: var(--secondary-text);
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  background: var(--card-background);
}

.search-input {
  border: none;
  outline: none;
  background: var(--card-background);
  color: var(--secondary-text);
  width: 210px;
}

.filter-btn {
  display: inline-flex;
  gap: 10px;
  width: 86px;
  height: 32px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
}

.quick-view-pos-table {
  border: 1px solid var(--box-border);
  border-radius: 4px;
}

.quickview-close-button {
  position: absolute;
  top: -32px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #383FEC;
  background-color: #F3F4FF;
  width: 71px;
  height: 32px;
  font-size: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.quick-view-footer {
  width: 100%;

  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.10);
  height: 40px;
}

.quick-view-title {
  font-size: 16px;
  font-weight: 700;
}

.quick-view-exit-selected {
  height: 32px;
  padding: 0px 12px;
  background: #989898;
  color: white;
  border: none;
  border-radius: 4px;
}

.quick-view-exit-selected.enabled {
  background: linear-gradient(180deg, #FF420E 0%, #FF702E 100%);
  height: 31px;
}

.quick-view-tab-btn {
  height: 28px;
  font-size: 16px;
  background-color: var(--card-background);
  color: #989898;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
}

.quick-view-tab-btn.active {
  color: var(--primary);
}

.quick-view-pos-table-wrapper {
  overflow-y: auto;
  max-height: 200px;
  scrollbar-width: none;
}

.dummy-box {
  height: 500px;
  width: 100%;
  background-color: lavender;
}

/*  ================================ Orders Page CSS =================================== */
.orders-page {
  grid-column: 1 / span 16;
  margin-top: 1rem;
}

.order-head {
  background-color: var(--header-bg);
}

.orders-page .btn-group-1 {
  gap: 0.75rem;
}

.orders-page .btn-1 {
  background-color: var(--btn-bg);
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  height: 30px;
  color: var(--text-color);
}

.orders-page .exit {
  background-color: #c1c1c1;
  color: var(--text-color-light-primary);
  height: 30px;
}

.orders-page .btn-1.active {
  color: var(--primary);
  background: var(--card-background);
  border: 1px solid var(--primary);
  border-radius: 4px;
}

.orders-page .all {
  width: 30px;
}

.orders-page .executed {
  width: 85px;
}

.orders-page .pending,
.rejected {
  width: 75px;
}

.orders-page .cancel {
  width: 118px;
  color: var(--text-color);
  background: #C1C1C1;
}

/* .orders-page .product {
  width: 73px;
  height: 24px;
  background: #FFEDCB;
  color: #E2A838;
} */

.orders-page th,
tr {
  color: var(--primary);
  font-size: 0.875rem;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.orders-page td {
  font-size: 0.875rem;

  font-weight: 500;
}

.orders-page tr {
  color: var(--text-color);
}

.orders-page table thead th {
  background: var(--header-bg);
}

.orders-page .table {
  border-collapse: separate;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  border-spacing: 0px 0px;
}

.disabled-cancel-button {
  background-color: #C1C1C1 !important;
  pointer-events: none !important;
  color: white;
  border: none !important;
}

.selected-order {
  background: var(--card-background);
}

.order-message {
  font-size: 14px;
  font-weight: 500;
}

.order-message-body {
  border: 1px solid #D9D9D9;
}

.last-order-margin {
  margin-bottom: 155px !important;
}

.info-icon {
  height: 18px;
  width: 18px;
  display: inline-block;
  text-align: center;
  border: 1px solid var(--text-color);
}

/*  ============================== Option Chain Page CSS ================================= */

.options-menu {
  display: grid !important;
  grid-auto-flow: column;
}

.option-chain-table-container {
  grid-column: span 16;
  gap: 16px;
}


.symbol-selection {
  border-radius: 4px;
  padding: 16px 14px;
  min-width: 382px;
  width: 510px;
  height: 126px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.symbol-info .symbol-name {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}


.header-box {
  padding: 14px;
}

.market-info {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-info .lp {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.symbol-info .lp-decimal {
  font-weight: 400 !important;
}

.symbol-info .ch-chp {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 4px;
}

.symbol-info .search {
  border-radius: 2px;
  border: 1px solid #C1C1C1;
  height: 32px;
  width: 32px;
}

.search-svg {
  stroke: var(--primary);
}


.custom-range-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.custom-range {
  width: 232px;
  height: 8px;
  background: var(--filler);
  appearance: none;
  outline: none;
}

.custom-range-pos-left {
  width: 100%;
  height: 8px;
  background: #ebebeb;
  appearance: none;
  outline: none;
}

.slider-pos-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 8px;
  flex-shrink: 0;
}


.custom-range::-webkit-slider-thumb {
  appearance: none;
  width: var(--thumb-width);
  height: 8px;
  background: var(--slider-color, #5CA81D);
  margin-left: var(--thumb-position);
}

.redBarColor {
  --slider-color: #DD6565;
  background: #5CA81D !important;
}

.greenBarColor {
  --slider-color: #5CA81D;
  background: #DD6565 !important;
}

.slider {
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  width: 11px;
  height: 8px;
  flex-shrink: 0;
}

.range-label {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-most-traded .most-traded-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}

.symbol-most-traded .most-traded {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  padding: 5px 10px;
  background: #f4f4f4;
}

.symbol-most-traded-active {
  background: #F3F4FF !important;
  color: var(--primary) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}


.symbol-date .date {
  width: 101px;
  height: 30px;
  border-radius: 4px;
  background: #F4F4F4;
  font-size: 14px;
  font-weight: 500;
}

.symbol-date .date.active {
  background: #E3E4FF;
  color: var(--primary);
}

.expiry-date-title {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.modal {
  background: rgba(0, 0, 0, 0.3);
}

/* Seach Modal CSS */
.search-modal.modal {
  position: absolute;
  /* left: 100px;
  bottom: 200px; */
}

.ps-2 {
  color: var(--text-color);
  background-color: var(--card-background);
}

.search-modal .modal-header {
  border-bottom: none;
}

.search-modal .modal-dialog {
  width: 319px;
  height: auto;
  position: absolute;
  left: 9rem;
  min-height: auto;
  margin-top: 16rem;
  background-color: var(--card-background);
}


.search-modal .modal-content {
  background-color: var(--card-background);
  border-radius: 4px !important;
  /* margin-top: 115rem; */
  max-height: 44rem;

}

.search-modal .modal-body {
  max-height: 70rem;
  overflow-y: hidden;
}


.search-modal input {
  width: 243px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.search-modal .pop-searches-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.search-modal .pop-searches-title:hover {
  background-color: var(--card-background);
}

.search-modal .pop-searches-symbol {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  color: var(--text-color);
}


.search-modal .index {
  width: 44.788px;
  height: 20px;
  background: #EFEFEF;
  color: #1F1E1E;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.search-modal li {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.search-modal li:hover {
  background-color: var(--header-bg);
  color: var(--text-color);
}

.btn-close {}

/* =============================  data ======================= */
.data-group {
  /* width: 100%; */
  grid-column: span 10;
  width: 870px;
}

.data {
  height: 64px;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
  /* width: 865.496px; */
}

.empty-basket-text {
  color: var(--secondary-text);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.data-group-title {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.data-group-value {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.basket-icon {
  padding: 0px 20px;
  background: var(--primary);
  /* background: linear-gradient(135deg, #757AFA 1.92%, var(--primary) 100%); */
}

.clear-basket-btn {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.react-modal-content {
  color: #000;
}

tr:hover {
  /* filter: brightness(0.9); */
}


.scalp-svg {
  display: flex;
  width: 24px;
  height: 24px;
  font-size: 20px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
}

.open-buy-sell-modal-btn {
  background: white;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.basket-data-values {
  padding: 12px 16px 12px 0;
}

.date-dropdown-btn {
  padding: 5px 10px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #989898;
  background: var(--card-background);
}

.oc-date-dropdown-menu {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #989898;
  background: #FFF;
}

.dropdown-item {
  color: var(--text-color);
}

/* Add this style to your CSS */
.date-dropdown-btn::after {
  display: none;
}

.expiry-days {
  font-size: 12px;
}

.settings,
.open-basket {
  padding: 6px 12px;
  border-radius: 4px;

}

.open-basket-disabled {
  pointer-events: none;
  opacity: 0.2;
  background: #DADADA;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  background: #DADADA;
  color: var(--text-color);
}

.settings {
  border: 1px solid #000;
  background: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.open-basket {
  background: var(--primary);
  color: var(--text-color-light-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.basket-badge {
  border-radius: 50%;
  padding: 4px 10px;
  background: #FFE608;
  font-size: 12px;
  font-weight: 500;
}

/* Option Chain Table CSS */

.sticky-header {
  position: sticky;
  top: 0;
  background-color: var(--header-bg);
  z-index: 100;
}

.table-wrapper {
  overflow: auto;
  max-height: calc(100vh - 270px);
}

/* .table-wrapper-sc-modal-desktop {} */

.table {
  width: 100%;
}


.table-wrapper::-webkit-scrollbar {
  display: none !important;
  width: 0px;
  scrollbar-color: transparent;
}

.table-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
}

.option-chain-table-container {
  overflow: hidden;
  margin-top: 16px;
}

.option-chain-table-container table {
  margin-right: 0;

}

.option-chain-table {
  border: 1px solid #DADADA;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.option-chain-table .calls-puts {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;

}

.option-chain-table .calls {
  color: #E2A838;

}

.option-chain-table .puts {
  color: #757AFA;
}

.option-chain-table .table-header-columns {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background-color: var(--header-bg);
  color: var(--primary);
}

.table-responsive.tabla {
  overflow-y: auto;
}

/* Hide the vertical scrollbar */
.table-responsive.tabla::-webkit-scrollbar {
  width: 0em;
}

.table-responsive.tabla::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}


.option-chain-table .table-header-columns {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.strike-header {
  margin-top: none !important;
}

.option-chain-table td {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
}

.option-chain-table .strike {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border: 1px solid var(--box-border);
  background-color: var(--strike-bg);
}

/* Styles for yellow rows (calls side) */
.option-chain-table tbody tr.yellow {
  background-color: #FFF9E5;
}

/* Styles for blue rows (puts side) */
.option-chain-table tbody tr.blue {
  background-color: #F4F5FF;
}

.max-pain-row {
  padding: 0.07rem !important;
}

.max-pain-label {
  width: 54px;
  height: 14px;
  color: var(--primary);
  font-size: 10px;
  font-weight: 500;
  line-height: 0px;
  background: var(--header-bg);
}

/* Setting Modal CSS */
.settings-modal .modal-header {
  border-bottom: none;
}


.settings-modal .modal-content {
  border-radius: 4px !important;
  width: 546px;
  height: 339px;
  background-color: var(--card-background);
}

.settings-modal .modal-title {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.settings-modal .modal-tag-titles {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.settings-modal li {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.settings-modal .ltp-range {
  width: 115px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}


.settings-modal .default-dots {
  width: 65px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.settings-modal .dropdown-toggle {
  width: 80px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
}

.settings-modal .modal-footer {
  border-top: none;
}

.settings-modal .reset-btn {
  width: 75px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;
  background: var(--card-background);
}

.settings-modal .apply-btn {
  width: 80px;
  height: 36px;
  border-radius: 4px;
  background: var(--primary);
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;
  border: none;
}

.strike-range-container {
  width: 262px;
}

.selected-slider-value {
  width: 47px;
  height: 24px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.strike-slider-min-max {
  font-weight: 500;
  font-size: 14px;
}

#customRange {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 16px;
  outline: none;
}

#customRange::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  border-radius: 16px;
}

#customRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #383FEC;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-top: -6px;

}

#customRange::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  background: #f1f1f1;
  border-radius: 16px;
}

#customRange::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #383FEC;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#customRange::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}



#customRange::-ms-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #383FEC;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* ==================== STRATEGY CREATOR PAGE CSS =========== */

.left {
  gap: 12px;
  width: fit-content;
}

.right {
  gap: 12px;
  width: 100%;
}

.limit-banner {
  width: 510px;
  height: 66px;
  border-radius: 4px;
  font-weight: 500;
  background: linear-gradient(89deg, #FFB4B4 0.16%, #CA4D4D 68.8%);
}

.limit-risk-text {
  font-size: 16px;
}

.limit-risk-btn {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--btn-bg);
  padding: 6px 12px;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.toast-text {
  color: #000;
}

.create-strategy {
  width: 510px;
  border-radius: 4px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.header-box {
  padding: 14px;
}

.create-strategy .bullish {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.bullish-trend {
  color: #5CA81D;
  font-weight: 700;
  line-height: normal;
}

.bearish-trend {
  color: #DD6565;
  font-weight: 700;
  line-height: normal;
}

.neutral-trend {
  color: var(--primary);
  font-weight: 700;
  line-height: normal;
}

.strategy-table>:not(caption)>*>* {
  padding: 0.5rem 0rem;
  border: none;
}

.table-first-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 var(--secondary-text)555;
  height: 210px;
  width: fit-content;
}

.table-first-container::-webkit-scrollbar {
  width: 6px;
}

.table-first-container::-webkit-scrollbar-track {
  background-color: var(--secondary-text)555;
}

.table-first-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.create-strategy-btn {
  width: 119px;
  height: 31px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
}

.btn-op {
  color: var(--primary);
  border: 1px solid var(--primary);
  background: var(--card-background);
}

.btn-screener {
  color: #fff;
  background: linear-gradient(180deg, #FF420E 0%, #FF702E 100%);
  border: none;
}

.btn-date {
  font-size: 12px;
  /*changed to 11 for now*/
  font-weight: 500;
  line-height: 0px;
  width: 84px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: var(--card-background);
  color: var(--primary);

}

.strategy-strike {
  color: var(--text-color);
  width: 88px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  background: var(--card-background);
}


.strategy-lots {
  color: var(--text-color);
  width: 88px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  background: var(--card-background);
}

.strategy-lots span:active {
  transform: scale(0.9);
}

.strategy-strike span:active {
  transform: scale(0.9);
}

.plus {
  color: var(--plus);
}

.plus-text {
  color: var(--text-color);
}

.price {
  color: var(--primary);
  width: 84px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
}

.price-input {
  width: 60px;
  border: none;
  outline: none;
  text-align: end;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  background-color: var(--card-background);
}

.more {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}


.create-strategy-actions {
  height: 63px;
  border-radius: 0px 0px 4px 4px;
  background: var(--header-bg);
  padding: 0 14px;
}

/* //////////////////////////////// */
.toggle-container {
  display: flex;
  width: 135px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  background: var(--toggle-btn);
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
}

.toggle-button {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  outline: none;
  border: none;
  background: var(--toggle-btn);
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 27px;

}

.toggle-button.active {
  background: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  border-radius: 2px;
  width: 49px;
  height: 24px;
}

.lotx {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.execute-order-btn {
  border-radius: 4px;
  background: var(--primary);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
}

.virtual-button {
  height: 30px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
  justify-content: center;
}

.creator-bottom {
  padding: 8px 14px;
}

.creator-bottom-title {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.creator-bottom-value {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.confirm-popup-ok {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  background: #70D11F;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.risk-factor {
  width: 510px;
  height: 280px;
  border-radius: 4px;
  overflow: hidden;
  padding: 14px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.filter-btns {
  border-radius: 4px;
  background: var(--btn-bg);
  border: var(--box-border);
  height: 30px;
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.filter-btns.active {
  color: var(--primary);
  background: var(--card-background);
  border: 1px solid var(--primary);
  border-radius: 4px;
}

.box-container {
  height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 var(--secondary-text)555;
}

.box-container::-webkit-scrollbar {
  width: 6px;
}

.box-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.box-container::-webkit-scrollbar-track {
  background-color: var(--secondary-text)555;
}

.box {
  border-radius: 4px;
  border: 1px solid var(--box-border);
  box-shadow: 0px 3px 20px #162B740A;
}

.iron-title {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.iron-text {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.low-risk {
  padding: 0.5px 4px;
  background: #FFEDCB;
  color: #E2A838;
  font-size: 12px;
  font-weight: 500;
}

.high-risk {
  padding: 0.5px 4px;
  background: #FFCDCD;
  color: #DD6565;
  font-size: 12px;
  font-weight: 500;
}

.hedged {
  padding: 0.5px 4px;
  background: #D8FFB8;
  color: #5CA81D;
  font-size: 12px;
  font-weight: 500;
}

.unhedged {
  padding: 0.5px 4px;
  background: #FFCDCD;
  color: #DD6565;
  font-size: 12px;
  font-weight: 500;
}

.disabled-delete {
  cursor: default;
  color: #C1C1C1;
  background-color: var(--card-background);
}

/* Right side of strategy creator  */
.strategy-menu {
  border-radius: 4px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
  padding: 1rem;
}

.strategy-menu-value {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}


.strategy-instruments {
  height: 286px;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.table-container {
  overflow-x: auto;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 var(--secondary-text)555;

}

.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background-color: var(--secondary-text)555;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.strategy-tablee {
  width: 100%;
  border-collapse: collapse;
}

.tr-strategy-headerr,
.screener-tbl-header {
  background: var(--header-bg);
  position: sticky;
  top: 0;
  /* z-index: 1; */
  border: 1px solid var(--box-border);
}


/* .tr-strategy-headerr{
  padding: 0 14px;
} */


.tr-strategy-headerr th {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.screener-tbl-header {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 0px;
}

.table-cell {
  padding: 4px 28px;
  white-space: nowrap;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.iv {
  color: var(--text-color);
  width: 54px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
}


.strategy-graph {
  /* width: 864px !important; */
  border-radius: 4px;
  gap: 8px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

/* #container {
  width: 100%;
  height: 300px;
} */

.graph-target-day-container {
  gap: 10px;
}

.graph-text {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.main-graph {
  width: 795px;
  height: 349px;
  border: 2px solid green;
}

/* Custom styles for the range input */
#custom-range {
  width: 273px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #D9DBE9;
}

/* #custom-range::-webkit-slider-thumb {
  width: 16px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary) !important;
  cursor: pointer;
  -webkit-appearance: none;
} */

#custom-range::-moz-range-thumb {
  width: 16px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary) !important;
  cursor: pointer;
  -webkit-appearance: none;
}

#custom-range::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--primary) !important;
  cursor: pointer;
  -webkit-appearance: none;
}

.target-date-dropdown-menu {
  height: 94px;
  overflow-y: auto;
  width: 120px !important;
  font-size: 14px;
  font-weight: 500;
  transform: translate3d(0px 25px 0px) !important;
  padding: 0 !important;
}

.target-date-dropdown-item {
  padding: 4px 8px;
  text-align: center;
  color: var(--text-color);
}

.estimated-total {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.graph-footer-1 {
  position: absolute;
  bottom: calc(100% + 4px);
  display: inline-flex;
  padding: 6px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primary);
  color: var(--text-color);
  font-size: 12px;
  text-align: center;
  pointer-events: none;
}

.graph-footer-2 {
  width: 0;
  height: 0;
  border-top: 8px solid var(--primary);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  pointer-events: none;


}




/* Screener Modal CSS */
.screener-modal .modal-content {
  width: 464px;
  height: 359px;
  border-radius: 0px;
  border: none;
  background: var(--card-background);
}

.screener-modal .modal-header {
  padding: 1rem 1rem 0 1rem;
}

.fixed-section {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.scrollable-section {
  flex: 1;
  overflow: auto;
  padding: 10px;
}

.screener-modal thead {
  border: 1px solid #EFEFEF;
}

.screener-modal .modal-header .screener-name {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.screener-moadal-table>:not(caption)>*>* {
  padding: 0.5rem 0rem;
  border: none;
}

.screener-moadal-table {
  border-collapse: collapse;
  width: 100%;
}

.screener-modal-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 var(--secondary-text)555;
  height: 150px;
}

.screener-tbl-header th {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.expiry-dropdown-screener {
  width: 108px !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.greek-dropdown-screener {
  width: 71px !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

.screnner-modal-dropdown .btn {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;

}

.screening-condition .dropdown-toggle::after {
  margin-left: 3.9em;
}

.screening-condition .dropdown-toggle {
  position: relative;
}

.screening-condition .dropdown-toggle::after {
  content: "";
  position: absolute;
  right: 10px;
}

.screnner-modal-dropdown .dropdown-menu {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  --bs-dropdown-min-width: 4rem;
  width: -webkit-fill-available;
}

.screnner-modal-dropdown li {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dropdownn button {
  width: 122px;
  height: 36px;

}

.scr-con {
  width: 39px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
}

/* Disable number input spinners */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row-close {
  width: 11px;
  height: 11px;
}

.modal-footer .d-flex button {
  height: 37px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.modify-btn-container-quickeview {
  position: absolute;
  display: flex;
  right: 73%;
  top: 46%;
  z-index: 100;
  gap: 5px;
}

.modify-btn-container-live {
  position: absolute;
  display: flex;
  right: 79%;
  bottom: 74%;
  z-index: 100;
  gap: 5px;
}

.modal-footer .cancel {
  display: inline-flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--box-border);
  background: #FFF;
  color: var(--text-color);

}

.modal-footer .saveandscreen {
  display: flex;
  width: 112px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--primary);
  color: var(--text-color-light-primary);
  border: 1px solid var(--primary);
}

.screener-modal .modal-footer,
.modal-header {
  border: none;
}

/* ScreenerModal.css */

.disabled-row {
  /* opacity: 0.2; */
}

.disabled-row .bs-value {
  background-color: #c1c1c1;
  color: var(--text-color);
}

.disabled-row .bs-value {
  background-color: #c1c1c1 !important;
  color: white !important;
}

.disabled .ce {
  border: 1px solid #c1c1c1 !important;
  color: #c1c1c1 !important;
}

.disabled-row .strategy-lots span {
  color: #c1c1c1;
}

.disabled-row .dropdown-toggle {
  border-color: #c1c1c1;
  color: #c1c1c1;
}

.disabled-row input[type="checkbox"] {
  cursor: not-allowed;
}

.disabled-row .scr-con {
  border-color: #c1c1c1;
  color: #c1c1c1;
}

.disabled-row span[role="button"] svg {
  fill: #c1c1c1;
}

.disabled {
  pointer-events: none;

}

.disabled-pos-row {
  background-color: var(--box-border);
}

/* ============================ Positions Page CSS =============== */

.pos-left {
  width: 371px;
  border-radius: 4px;
  height: 189px;
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
}

.pos-funds {
  height: 140px;
  padding: 15px;
}

.add-funds-btn {
  display: flex;
  gap: 6px;
  height: 32px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
  padding: 6px 12px;
}

.funds-text {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.funds-text-description {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
}


.pos-right {
  width: 100%;
  min-height: 100vh;
}

.symbol-name-pos {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.text-green {
  color: #5CA81D;
}

.text-red {
  color: #DD6565;
}

.watchlist {
  height: 864px;
  border: 1px solid #989898;
  border-radius: 4px;
}

.fandopositions {
  color: var(--text-color);
  font-size: 24px;
  font-weight: 700;
}

.fandopositions-description {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
}

.pos-menu {
  height: 66px;
  border-radius: 4px;
  border: 1px solid #989898;
}

.pos-strategy-menu {
  height: 189px;
}

.pos-strategy-menu-value {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}

.position-analytic {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
}


.custom-toast {
  bottom: 5em;
  left: 37%;
  width: 300px;
  height: fit-content;
  background-color: #F9EBD3;
  color: var(--text-color);
}


.position-analytic-description {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;

}

.exit {
  margin-left: auto;
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  height: 30px;
}


.exit.enabled {
  background: linear-gradient(180deg, #FF420E 0%, #FF702E 100%);
  height: 31px;
}


.span-bold {
  font-weight: 700;

}

.trade-mode-mobile {
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.trade-mode-text {
  font-weight: 700;

}

.virtual-not-button {
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  color: #9B9B9B;
  border: 1px solid #9B9B9B;
  background: var(--card-background);
}

.virtual-not-button2 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  /* display: flex; */
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  color: #9B9B9B;
  border: 1px solid #9B9B9B;
  background: var(--card-background);
}

.execute-order-not-btn {
  border-radius: 4px;
  background: #989898;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.group-container,
.pos-table-style {
  background: var(--card-background);
  box-shadow: 0px 3px 20px #162B740A;
  border-radius: 4px;
}

.position-table-header {
  background: var(--header-bg);
  position: sticky;
  top: 0;
  z-index: 1;
  height: 36px;
}

.position-table-header th {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
}

/* Common styles for both product types */
.product-type {
  width: 73px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.product-type.Normal {
  color: var(--primary);
  background: #DBDCFF;

}

.product-type.Intraday {
  color: #E2A838;
  background: #FFEDCB;
}

.product-type-zero-quantity {
  /* color: white !important;
  background-color: #c1c1c1 !important; */
}

/* Common styles for both "BUY" and "SELL" */
.buy-sell {
  text-align: center;
}

.pos-buy {
  color: #5CA81D !important;
}

.pos-sell {
  color: #DD4B27 !important;
}

.position-table tr {
  height: auto;
}

.position-table .table-data {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.group-menu {
  margin-bottom: 0px;
}

.analyse-op {
  height: 32px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: var(--card-background);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.pos-exit-analyse {
  width: 56px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #989898;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.pos-exit-analyse,
.analyse-op.enabled {
  background: var(--primary);
  color: var(--text-color-light-primary);

}

.quickview-exit-pos.enabled {
  background: var(--primary);
  color: var(--text-color-light-primary);
}


.quickview-exit-pos {
  height: 32px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #989898;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.analyse-op.enabled {
  background: var(--card-background);
  color: var(--primary);
  border-color: var(--primary);

}

.analyse-op.disabled {
  background: white;
  color: #989898;
  border-color: #989898;

}

.analyse-op.disabled svg {
  fill: #989898 !important;
  /* Set the SVG fill color for the disabled state */
}

.pos-exit-analyse.disabled {
  background: #989898;
  color: #FFF;
}

.quickview-exit-pos.disabled {
  background: #989898;
  color: #FFF;
}

.strategy-name {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

}

.pos-custom-range {
  width: 244px;
  height: 8px;
  background: #DD6565;
  appearance: none;
  outline: none;
}

.pos-slider {
  width: 70px;
  background-color: #5CA81D;
  position: absolute;
  top: 92%;
  transform: translateY(-50%);
  height: 8px;
  flex-shrink: 0;
}

.pos-slider::before,
.pos-slider::after {
  content: "";
  position: absolute;
  /* height of black edges */
  top: -4px;
  bottom: -4px;
  width: 4px;
  background-color: black;
}

.pos-slider::before {
  left: 0px;
}

.pos-slider::after {
  right: 0px;
}

/* Watchlist */

.watchlist-title {
  color: var(--secondary-text);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.watchlist-search {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--secondary-text);
}

.list-elements {
  border-bottom: 1px solid var(--box-border);
}

.list-symbol-title,
.list-symbol-title-value {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.symbol-list-index {
  height: 20px;
  background: #EFEFEF;
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}



/* //////////////////////////////////// */
.list-hover-value {
  display: block;
}

.list-hover-buttons {
  display: none;
}

.list-elements:hover .list-hover-value {
  display: none;
}

.list-elements:hover .list-hover-buttons {
  display: block;
}

.hamburger-btn {
  border: 1px solid #989898;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buy-btn-mobile {
  border-radius: 2px;
  background: rgb(112, 209, 31);
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  border: none;
  z-index: 100;
  height: 24px;
  width: 42px;
}

.sell-btn-mobile {
  border-radius: 2px;
  background: rgb(255, 57, 8);
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  border: none;
  z-index: 100;
  height: 24px;
  width: 42px;
}

.buy-btn {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: #ffffff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  border: none;
  z-index: 100;
}

.b-tag {
  width: 18px;
  height: 17px;
  color: #19AF55;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  position: absolute;
  left: -3%;
  top: 33%;
  font-size: 13px;
}

.b-tag-mobile {
  width: 34px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 4px 12px 4px 12px;
  background-color: white;
  color: #19AF55;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  font-size: 16px;
}

.trade-placing-child {
  width: 388px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s-tag-mobile {
  width: 34px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 4px 12px 4px 12px;
  background-color: white;
  color: #FF3808;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  font-size: 16px;
}

.scalp-svg-mobile {
  border: 1px solid black;
  border-radius: 4px;
  align-content: center;
  text-align: center;
  width: 34px;
  height: 30px;
}

.s-tag {
  width: 18px;
  height: 17px;
  color: #FF3808;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  position: absolute;
  left: -3%;
  top: 33%;
  font-size: 13px;
}

.bp-tag {
  width: 18px;
  height: 17px;
  color: #19AF55;
  ;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  position: absolute;
  right: -3%;
  top: 33%;
  font-size: 13px;
}

.sp-tag {
  width: 18px;
  height: 17px;
  color: #FF3808;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  position: absolute;
  right: -3%;
  top: 33%;
  font-size: 13px;
}

.lots-input-mobile {

  width: 50%;
  height: 100%;
  font-size: 14px;
  color: black;
  text-decoration: none;
  border: none;
  outline: none;
  padding-right: 5px;
  margin: 0;
  text-align: center;
  align-content: center;
}

.top-optionchain-page {
  width: 397px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #CBCBCB;
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: white;
  align-items: center;
}

.info-div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  margin-left: 5px;
}

.info-price {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.info-change {
  width: 92px;
  height: 19px;
  font-size: 14px;
}

.information {
  width: 62px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  gap: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #383FEC;
}

.icon-div-optionchain {
  width: 14px;
  height: 14px;
  color: #383FEC;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.info-type {
  width: 21px;
  height: 16px;
  color: #383FEC;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.details-optionchain {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.symbol-name-style {
  width: 44px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #1E1E1E;
}

.trade-placing-div {
  display: flex;
  flex-direction: row;
  z-index: 1035;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  position: fixed;
  bottom: 6%;
  left: 0%;
  width: 100vw;
  height: 62px;
  background-color: var(--header-bg);
  box-shadow: 0px -4px 4px 0px #383FEC1A;
}

@media screen and (max-height: 740px) and (min-height: 667px) {
  .trade-placing-div {
    bottom: 7.5%;
  }
}

.trade-placing-div-modal {
  display: flex;
  flex-direction: row;
  z-index: 1035;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100vw;
  height: 62px;
  background-color: var(--header-bg);
  box-shadow: 0px -4px 4px 0px #383FEC1A;
  ;
}

.strike-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust spacing between strike and tags */
}

.buy-btn.active {
  background: rgb(112, 209, 31);
  color: white;
}

.b-tag-mobile.active {
  background-color: rgb(112, 209, 31);
  color: white;
}

.s-tag-mobile.active {
  background: rgb(255, 57, 8);
  color: white;
}

/* 
.strike-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height:normal;
  border: 1px solid var(--box-border);
  background-color: var(--strike-bg);
} */

.discover-mobile-main-div {
  position: relative;
}

.top-header {
  width: 428px;
  height: 100px;
  display: flex;
  position: fixed;
  left: 0;
  background-color: white;
  z-index: 100;
}


.top-header-child {
  width: 285px;
  height: 70px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;

}

.top-header-username {
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.4px;
}

.top-header-text {
  width: 285px;
  height: 38px;
  top: 47px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  color: #000000;
}

.scroll-items {
  /* position: absolute; */
  /* height: fit-content; */
  /* max-height: 1705px;  */
  overflow-y: auto;
  /* background: linear-gradient(167.94deg, rgba(254, 255, 226, 0.18) 13.48%, rgba(92, 168, 29, 0.1) 100%); */
  /* background-color: #000000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 100px;
  margin-top: 30px;
}

.bar-details {
  width: 150px;
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-container {
  width: 100%;
  /* Take full width of the container */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center horizontally */
  gap: 0px;
}

.bar-text {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.bar {
  height: 4px;
}

.expand-market-container {
  margin-top: 10px;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  padding-bottom: 18px;
  gap: 14px;
}

.expand-market-heading {
  height: 41.64px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.expand-market-text-info {
  width: 202px;
  height: 41.64px;
  display: flex;
  flex-direction: column;
}

.expand-market-icon {
  width: 34px;
  height: 14px;
  cursor: pointer;
}

.expand-market-assets {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  position: relative;
  padding: 10px;
}

.expand-market-asset {
  display: flex;
  flex-direction: column;
}


.expand-market-asset-main {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px var(--box-border) solid;
  /* margin: auto; */
  margin: 0 10px 0 0;
}

.market-asset-heading {
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.asset-info {
  width: 50%;
  height: 22px;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  gap: 5px;
  align-items: center;
}

.asset-name {
  width: auto;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: var(--text-color);
}

.asset-market {
  width: auto;
  height: 14px;
  border-radius: 4px;
  padding: 0px 4px;
  background-color: var(--card-background);
  text-align: center;
  font-size: 10px;
  color: var(--text-color);
}

.asset-goto {
  font-size: 21px;
  color: var(--primary);
  transform: rotate(45deg);
  position: relative;
}

.market-asset-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.asset-price {
  width: fit-content;
  height: 22px;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}

.asset-24hrsgain {
  height: 19px;
  font-size: 14px;
  font-weight: 400;
}

.indian-market-div {
  margin-top: 10px;
  width: 100%;
  height: 63.64px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  gap: 28px;

}

.expand-global-main {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--box-border);
  border-radius: 4px;
  padding: 6px 12px;
  gap: 3px;
  margin: 0 10px 0 0;
}

.expand-global-heading {
  height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.global-heading-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.global-heading-left h2 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.global-active,
.global-inactive {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-active {
  background-color: #19af55;
}

.global-inactive {
  background-color: #6e6e6e;
}

.global-heading-right {
  font-weight: 700;
  font-size: 14px;
  line-height: 18.9px;
}

.expand-global-second {
  height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.global-price {
  height: 19px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: center;
  margin: 0;
}

.expand-market-global {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  position: relative;
  padding: 10px;
}

.global-gain {
  width: fit-content;
  height: 19px;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.expand-global-container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  gap: 14px;
  padding-bottom: 10px;
}

.nifty-dropdown-div {
  width: 100%;
  height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 100px;
}

.tabsContainer {
  height: 40;
  border-radius: 4px;
  background-color: var(--card-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  margin-left: 11px;
}

.tabItem {
  height: 32px;
  width: 85px;
  cursor: pointer;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedTab {
  background-color: transparent;
  height: 32px;
  width: 85px;
  border-radius: 4px;
  text-align: center;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expand-performance-container {
  width: 100%;
  height: 298px;
  border-radius: 8px;
  background-color: var(--card-background);
}

.performance-div {
  border-bottom: 1px solid var(--box-border);
  padding: 10px;
  margin-left: 11px;
  display: flex;
  flex-direction: column;
}

.performance-left {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.performance-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.performce-name {
  font-size: 16px;
  line-height: 21.6px;
  font-weight: 500;
}

.performce-price {
  height: 19px;
  font-size: 14px;
  font-weight: 700;
}

.performance-left {
  flex: 1;
  display: flex;
  align-items: center;
}

.performance-second {
  display: flex;
  justify-content: space-between;
}

.sub-info-left-text {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-text);
}

.sub-info-right {
  display: flex;
  gap: 5px;
}

.last-div {
  margin-top: 10px;
  width: 100%;
  height: 63.64px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  background-color: var(--card-background);
  justify-content: space-between;
  align-items: center;

}

.heading-div {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-left: 11px;
}

.chart-svg {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  height: fit-content;
}

.chart-main-div {
  width: 100%;
  height: 258px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  padding-top: 11px;
}

.chart-warning {
  height: 54px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 247, 179, 0.55);
  justify-content: center;
  gap: 5px;
  align-items: flex-start;
  padding-left: 14px;
  align-items: center;
}

.market-price .integer-part {
  font-weight: bold;
}

.market-price .fractional-part {
  font-weight: normal;
}


.warning-text {
  width: 322px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

/* .chart-result{
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */
.performance-d {
  margin-top: 20px;
  width: 610px;
  height: 269px;
  display: flex;
  flex-direction: column;
}

.cards-header-d {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cards-subheader-d {
  display: flex;
  flex-direction: column;
}

.cards-heading {
  color: var(--text-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.cards-subheader {
  color: var(--secondary-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.performance-dropdown {
  display: inline-flex;
  padding: 6px 12px 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid var(--box-border);
  border-radius: 8px;
  background: var(--card-background);
  color: var(--primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheader {
  color: gray;
  font-size: 14px;
}

.performance-tabs-div {
  width: 610px;
  height: 40px;
  display: flex;
  margin: 10px auto;
  border-radius: 4px;
  background: var(--header-bg);
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}

.performance-tab {
  width: 144.832px;
  height: 32px;
  border: none;
  background-color: var(--btn-bg);
  cursor: pointer;
  border-radius: 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.performance-tab.active {
  width: 144.832px;
  height: 32px;
  border-radius: 4px;
  background: transparent;
  color: var(--primary);
  flex-shrink: 0;
  border: 1px solid var(--primary)
}


.performance-item-desktop {
  margin-top: 10px;
  padding: 0 2px 0 17.05px;
  width: 610px;
  height: 53px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--box-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.performance-item-body-d {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
  height: 99%;
}

.performance-item-name-d {
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.performance-item-exchange-d {
  width: 26px;
  height: fit-content;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: var(--card-background);
  color: var(--text-color);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto 0;
}

.performance-item-price-d {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.item-change {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.item-change.positive {
  color: green;
}

.item-change.negative {
  color: red;
}

.view-all {
  color: var(--primary);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  text-decoration: none;
  margin-top: 13px;
}

.view-all:hover {
  text-decoration: none;
}

.right-p-top-div {
  width: 610px;
  height: 264px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.cards-markets-info {
  width: 610px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  margin-top: 15px;
}

.opg-right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opg-cards-header {
  padding: 16px;
  background: var(--card-background);
  border-bottom: 1px solid var(--box-border);
  width: 100%;
  text-align: center;
}

.opg-cards-heading {
  font-size: 24px;
  margin: 0;
  color: var(--text-color);
}

.opg-cards-description {
  font-size: 14px;
  color: var(--secondary-text);
  margin: 0;
}

.opg-card {
  width: 190px;
  height: 91px;
  border: 1px solid var(--box-border);
  border-radius: 8px;
  background: var(--card-background);
  display: flex;
  flex-direction: column;
}

.opg-card-content {
  padding-left: 10px;
  padding-top: 5px;
}

.opg-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opg-card-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.opg-card-symbol {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
}

.opg-card-market {
  font-size: 10px;
  color: #888;
  background: var(--card-background);
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
}

.opg-card-icon {
  font-size: 20px;
  color: var(--primary);
}

.opg-card-body {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 5px;
}

.opg-card-price {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.opg-card-gain {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-content: center;
}

.opg-card-gain-up {
  color: #19AF55;
}

.opg-card-gain-down {
  color: red;
}

.opg-card-gain-text {
  margin: 0;
  font-size: 14px;
}

.opg-bar-details {
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
}

.opg-bar-container {
  display: flex;
  height: 4px;
  margin-bottom: 4px;
}

.opg-bar-green {
  background-color: green;
}

.opg-bar-red {
  background-color: red;
}

.opg-bar-text {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: var(--text-color);
}

.global-markets-list {
  margin-top: 15px;
  width: 610px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 12px;
}

.global-market-item {
  /* background: var(--background); */
  width: 180px;
  height: 60px;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px 49px;
}

.global-market-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 159px;
  height: 22px;
  flex-shrink: 0;
}

.global-market-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.global-market-symbol {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.market-status-active {
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
}

.market-status-inactive {
  width: 8px;
  height: 8px;
  background: gray;
  border-radius: 50%;
}

.global-market-points {
  font-size: 14px;
  font-weight: 500;
}

.global-market-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 159px;
  height: 22px;
  flex-shrink: 0;
}

.market-price {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.market-gain {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.cards-lower {
  width: 608px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--box-border);
  align-items: center;
  margin-top: 30px;
  background: var(--card-background);
}

.cards-lower-heading {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.cards-number {
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: var(--card-background);
  width: 64px;
  height: 14px;
  color: var(--text-color);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chart-div-desktop {
  width: 610px;
  height: 423px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--box-border);
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 29px;
  position: relative;
  background: var(--card-background);
}

.chart-warning-desktop {
  width: fit-content;
  height: 39px;
  flex-shrink: 0;
  background: rgba(255, 247, 179, 0.55);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 9px;
}

.warning-text-desktop {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.chart-desktop {
  position: relative;
  width: 505px;
  height: 238px;
}

.chart-result-desktop {
  height: 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 20;
  width: 100%;
  bottom: 28px;
}

.chart-result {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.market-mood-desktop {
  color: var(--text-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.label-on-chart {
  width: 100.619px;
  height: 35.617px;
  flex-shrink: 0;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.77);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0;
  z-index: 40;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  align-content: center;
}

.fiidii-desktop {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-top: 100px; */
  margin: 60px auto;
}

.fiidii-mobile {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
  margin: 100px auto 0 auto;
  overflow-y: auto;
}

.fiidii-table tbody tr {
  height: 40px;
  color: #000;
}

.filter-btns:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
  border: 1px solid #999;
  opacity: 0.6;

}

.active-page {
  background-color: var(--primary-color-dark);
  color: white;
  transform: scale(1.1);
  font-weight: bold;
}

.filter-btns:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.filter-btns:disabled:hover {
  background-color: #f0f0f0;

}


.fiidii-select {
  height: 35px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid var(--primary);
  outline: none;
  box-shadow: none;
}

.table-fiidii {
  width: 862px;
  height: 36px;
  flex-shrink: 0;
  border: 1px solid var(--box-border);
}

.fii-header-table {
  background: var(--header-bg);
  width: 862px;
  height: 36px;
  flex-shrink: 0;
  border: 1px solid var(--box-border);
  color: var(--primary);
}

.fii-table-th {
  border: 1px solid var(--box-border);
}

.table-fii-tr {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid var(--box-border);
}

.fii-texts {
  color: var(--text-color);
}

.date_range {
  width: 154px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #989898;
  display: flex;
  background: var(--card-background);
  align-items: center;
  justify-content: space-around;
}

.date_range_select {
  border: none;
  outline: none;
  background: var(--card-background);
  color: var(--text-color);
}

.date_range select:focus {
  border: none;
  outline: none;
}

.sell-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  border: none;
  z-index: 10;
}

.sell-btn.active {
  background: rgb(255, 57, 8);
  color: white;
}

.oc-page-desktop-table span {
  cursor: pointer;
}

.number {
  width: 88px;
  height: 24px;
  border: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.inc-dec-div {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  text-align: center;
  align-items: center;

}

.plus {
  /* width: 28px; */
  height: 12px;
  border-radius: 4px;
  /* background-color: #F5F5F5; */
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 0px 0px;
}

.plus-modal {
  width: 28px;
  height: 12px;
  border-radius: 4px;
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 0px 0px;
}

.lots-text {
  width: 19px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px;
  font-weight: 700;
  color: black;
}

.lots-text-mobile {
  width: 19px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px;
  font-weight: 700;
  color: var(--text-color);
}

.inc-dec-div button {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.lots-input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: black;
  text-decoration: none;
  border: none;
  outline: none;
  padding-right: 5px;
  margin: 0;
  text-align: right;
  align-content: center;
}

.minus {
  /* width: 28px; */
  height: 12px;
  border-radius: 4px;
  background-color: #F5F5F5;
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 0px;
}

.minus-modal {
  width: 28px;
  height: 12px;
  border-radius: 4px;
  /* background-color: #F5F5F5; */
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 0px;
}



.watchlist-tables,
.watchlist-tables tr,
.watchlist-tables th {
  color: var(--secondary-text);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.watchlist-tables>div:first-child table tbody tr td:last-child {
  border-right: 1px solid var(--box-border);
}

/* CSS For Option Chain Modal */
.option-chain-modal-content {
  border-radius: 0;
  height: 92vh;
  background-color: var(--card-background);
}

.option-chain-table {
  position: relative;
}

.virtual-position-buttons {
  position: absolute;
  display: flex;
  right: -2.5%;
  top: 15%;
  z-index: 100;
  gap: 5px;
}

.virtual-position-buttons-quickview {
  position: absolute;
  display: flex;
  left: 19%;
  top: 19%;
  z-index: 100;
  gap: 10px;
}

.live-position-buttons-quickview {
  position: absolute;
  display: flex;
  left: 19%;
  top: 19%;
  z-index: 100;
  gap: 10px;
}

.virtual-position-buttons-executed {
  position: absolute;
  display: flex;
  left: 20.5%;
  top: 19%;
  z-index: 100;
  gap: 10px;
}

.add-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  padding: 2px 12px;
  background-color: #19AF55;
  font-size: 12px;
  color: white;
}

.exit-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  padding: 2px 12px;
  background-color: #FF420E;
  font-size: 12px;
  color: white;
}

.modify-btn-container {
  position: absolute;
  display: flex;
  right: -0.5%;
  top: 15%;
  z-index: 100;
  gap: 5px;
}

.modify-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 52px;
  height: 24px;
  border-radius: 4px;
  padding: 2px 8px;
  background-color: #383FEC;
  font-size: 12px;
  color: white;
}

.position-table-row {
  position: relative;
}




.option-chain-table thead {
  position: sticky;
  top: -1;
  background-color: #fff;
  z-index: 998;
}

.analyse-chain-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px !important;
}

.analyse-chain-modal-content {
  border-radius: 0;
  width: 75%;
  background: var(--background);
}

.analyse-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.highcharts-credits {
  display: none !important;
}


/* //strategy creator dropdown-menu */
.strategy-table-row .dropdown-menu {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
  --bs-dropdown-min-width: 5rem;
}


/* .strategy-table-row{
  padding: 0 14px;
} */


.dropdown-item-hover:hover {
  background-color: var(--box-border);
  color: var(--text-color);
}

.hover-lots-value {
  width: 42px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: #FFF;
}

/* BASKET MODAL CSS */

.basket-modal .modal-content {
  border-radius: 0px;
  /* width: 700px; */
  min-height: 420px;
  background-color: var(--card-background);
}

.market {
  color: var(--primary);
}

.basket-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 876px;

}

.basket-builder-btn {
  width: 139px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  background: #FFF;
}

.basket-order-btn {
  width: 132px;
  color: var(--text-color-light-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  background: var(--primary);
  border: none;
}

.basket-order-not {
  width: 132px;
  color: var(--text-color-light-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  background: grey;
  border: none;
}

.add-target {
  height: 30px;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  color: var(--primary);
  font-size: 12px;
  font-weight: 500;
}

.basket-table-cell {
  padding: 4px;
  white-space: nowrap;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}


/* Buy sell modal css */
.buy-sell-modal .modal-content {
  border-radius: 4px;
  width: 676px;
  height: 346px;
  background-color: var(--card-background)
}

.buy-sell-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 676px;
  bottom: -40vh;

}

.buy-sell-modal .modal-title {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.buy-sell-impact-btn {
  width: 104px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #C1C1C1;
  background: white;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
}

.buy-sell-qty-btn {
  width: 121px;
  height: 40px;
  border-radius: 4px;
  background: #FF3908;
  border: #FF3908;
  color: var(--background);
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
}

.buy-sell-qty-btn-virtual {
  width: 121px;
  height: 40px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--card-background);
}

.buy-sell-btn-disabled {
  color: var(--text-color-light-primary);
  background: grey !important;
  width: 121px;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
}

.toggle-containerr {
  display: flex;
  width: 128px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--box-border);
  background: var(--toggle-btn);
  overflow: hidden;
  align-items: center;
  padding: 0px 3px;
}

.toggle-buttonn {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 28px;
  color: var(--buy-sell-btn-text);
}

.toggle-buttonn.buy {
  background: var(--toggle-btn);
  color: var(--buy-sell-btn-text) !important;
  border-radius: 4px;
}

.toggle-buttonn.sell {
  background: var(--toggle-btn);
  color: var(--buy-sell-btn-text) !important;
  border-radius: 4px;
}

.toggle-buttonn.buy.active {
  background: #70D11F;
  color: white !important;
}

.toggle-buttonn.sell.active {
  background: #FF3908;
  color: white !important;
}

.sell-bg {
  background: #FF3908;
}

.buy-bg {
  background: #70D11F;
}

.buy-sell-modal .btn-1 {
  background-color: #f4f4f4;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  height: 30px;
}

.buy-sell-modal .btn-1.active {
  background-color: black;
  color: var(--primary);
  background: #F3F4FF;
}

.marketorder,
.triggerorder,
.coverorder {
  width: 97px;
}

.limitorder {
  width: 89px;
}

.bracketorder {
  width: 106px;
}

.input-container {
  position: relative;
  width: 117px;
  height: 48px;
  background: #FFF;
}

.buy-sell-lot-input {
  height: 48px;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
}

.input-label {
  position: absolute;
  top: -2px;
  left: 10px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  transition: bottom 0.3s ease, font-size 0.3s ease;
  cursor: text;
  background: var(--card-background);
}

.input-label span {
  background: var(--card-background);
}

/* .input-label {
  position: absolute;
  top: 14px;
  left: 20px;
  color: #C1C1C1;
  transition: bottom 0.3s ease, font-size 0.3s ease;
} */

.number-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
  background: var(--card-background);
  color: var(--text-color);
  /* position: absolute; */
}

.buy-sell-inc-dec-div {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: auto;
}

.buy-sell-plus-minus {
  width: 28px;
  height: 50%;
  background-color: var(--strike-bg);
  font-size: 12px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.buy-sell-plus-minus:active {
  transform: scale(0.99);
}

.buy-sell-plus {
  border-radius: 0 4px 0 0;
}

.buy-sell-minus {
  border-radius: 0 0 4px 0;
}

.number-input-disabled {
  color: #C1C1C1;
}

.error-message {
  display: inline-block;
}

/* Tooltip box */
.error-message p {
  position: absolute;
  left: 0;
  top: 32px;
  padding: 5px 10px;
  width: 263px;
  font-size: 12px;
  color: #333;
  background-color: #fff;
  /* Light yellow background */
  border: 1px solid #c9c9c9;
  /* Gold border to mimic native form validation */
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Arrow pointing towards the input field */
.error-message p::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 15px;
  /* Adjust the left value to center the arrow */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c9c9c9 transparent;
}

/* Warning icon inside the error message */
.error-message p::after {
  content: "⚠️";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

/* The text of the message */
.error-message p span {
  margin-left: 25px;
  /* Space for the icon */
  display: inline-block;
}

.showErrorMessage {
  opacity: 1;
  visibility: visible;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  50% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}





.row {
  --bs-gutter-x: "0rem";
}

.tag {
  color: white;
  padding: 3px 6px;
}

.buy-tag {
  background-color: #70D11F;
}

.sell-tag {
  background-color: #FF3908;
}


/* =========== Mobile CSS ============= */
.mobile-header {
  display: none;
}


.desktop-header {
  display: block;
}

.mobile-screen {
  display: none !important;
  min-height: 100vh;
}

.flex-display {
  display: flex !important;
}

.desktop-screen {
  display: contents !important;
}

.desktop-screenn {
  gap: inherit;
  display: flex;
  grid-auto-flow: column;
}

.symbol-containers-mobile {
  width: max-content;
}

.footer {
  /* max-width: 420px; */
  height: 56px;
  background-color: var(--header-bg);

}

.footer-links-container {
  padding: 0px 12px;
}

.footer-link-names {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.active .footer-link-names {
  color: var(--primary);
}

.footer-links-container .active path {
  stroke: var(--primary);
}

/* Container for the outer scrolling container with fixed width */
.scroll-container-outer {
  width: 420px;
  overflow: hidden;
}

.scroll-container {
  display: flex;
  gap: 24px;
  white-space: nowrap;
  animation: scroll 5s linear infinite backwards;
}

.scroll-container li {
  flex-shrink: 0;
}

.scroll-container::-webkit-scrollbar,
.scroll-container-outer::-webkit-scrollbar {
  display: none;
}


.table-trading-symbol {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}


.bullish-table-menu {
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #DADADA;
  background: #FFF;
}



.strategy-table-dropdown .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.pos-table-more {
  padding: 8px;
  border: 1px solid #DADADA;
}

.shadow-container {
  background: var(--card-background);
  position: fixed;
  left: 0;
  bottom: 56px;
  width: 100%;
  z-index: 1;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
  height: auto;
}

.pos-check-buttons {
  margin-top: 10px;
}

.pos-button {
  height: 30px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

}

.one-click {
  color: var(--primary);
  width: 164px;
}

.exit-pos {
  width: 100%;
  color: var(--text-color-light-primary);
  background: var(--primary);
}

.collapsing-div {
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.collapsing-div.collapsed {
  max-height: 0;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.mobile-order-status {
  color: #5CA81D;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

/* BasketMobile.css */
/* CSS for BasketMobile component */
.basket-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.basket-mobile {
  z-index: 9999;
  height: 711px;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--card-background);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.analyse-mobile-modal {
  z-index: 9999;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  background: var(--background);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.screener-mobile-modal {
  z-index: 9999;
  height: 359px;
  display: grid;
  column-span: span 4;
  border-radius: 8px 8px 0px 0px;
  background: var(--card-background);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
}

.dropdown-menu {
  --bs-dropdown-min-width: max-content;
  background-color: var(--background);
}

.strategy-done {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primary);
  color: var(--text-color-light-primary);
  border: none;
}

.expanded-row-name {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
}

.expanded-row {
  background: var(--card-background);

}

.custom-indicator {
  width: 8px !important;
  height: 8px !important;
  background-color: #c1c1c1 !important;
  border-radius: 50%;
  margin: 0 5px;
  border: none;
  cursor: pointer;
}

.custom-indicator.active {
  background-color: var(--primary) !important;
}

.tools-dropdown-btn {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.tools-dropdown-content {
  width: 384px;
  height: auto;
  border-radius: 0px;

  border: none;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.12);

}

#dropdownMenuButton.dropdown-toggle.tools-dropdown-btn::after {
  display: none !important;
}

.tools-feature-title {
  font-size: 12px;
  color: var(--secondary-text);
  font-weight: bold;
}

.tools-label {
  font-size: 10px;
  font-weight: bold;
  color: #FF4511;
}

.tools-feature-name {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color);
}

.modal-backdrop {
  position: relative;
  --bs-backdrop-opacity: 0;
}

.menu-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
}

.menu-description {
  font-size: 14px;
  color: var(--text-color);
}

.virtual-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--secondary-text)555;
  margin: 12px 0 8px 0;
}

.virtual-feature {
  font-size: 16px;
  color: var(--text-color);
  text-decoration: none;
  padding: 12px;
}

.coming-soon {
  font-size: 14px;
  color: #ff4511;
  font-weight: bold;
}

.analyse-screener-page {
  /* margin-left: -30px; */
}

/* Styles for Analyse pages from tools */
.analyse-screener-page {
  margin-top: 1rem;
  /* margin-left: 15px; */
}

.screenerdata-heading {
  color: var(--text-color);
  font-weight: 700;
  font-size: 32px;
}

.screenerdata-desc {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
}

.filter-container {
  position: relative;
}

.popup-visible {
  width: 233px;
  height: 254px;
  position: absolute;
  top: calc(100% + 10px);
  left: -150px;
  border-radius: 12px;
  background: var(--card-background);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.filter-by {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}

.analyse-card {
  border-radius: 4px;
  border: 1px solid var(--box-border);
  background: var(--card-background);
  padding: 17px 20px 16px 20px;
  height: 172px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.view-more-card {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.card-pattern-name {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.card-tag {
  padding: 2px 8px;
  height: 23px;
  color: #FF420E;
  font-size: 14px;
  font-weight: 500;
  background: #FFE7E0;
}

.card-tag-green {
  padding: 2px 8px;
  height: 23px;
  color: #5CA81D;
  font-size: 14px;
  font-weight: 500;
  background-color: #EBFFDB;
}

.card-grid {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr))
}


.download-share-btn {
  border-radius: 4px;
  border: 1px solid var(--primary);
  background: var(--btn-bg);
  height: 32px;
  width: auto;
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  padding: 5px 6px;
}

.screenerdetails-table {
  --bs-table-bg: transparent;
}

.screenerdetails-table td {
  color: var(--text-color);
}

.screenerdetails-table-border-bottom {
  border-bottom: 1px solid var(--box-border);
  cursor: pointer;
}

.screenerdetails-table-border-bottom:hover {
  background: var(--card-background);
}

.last-updated {
  color: var(--secondary-text);
  font-size: 16px;
  font-weight: 400;
}

.time-frame {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.screenerdetails-table th {
  color: var(--secondary-text);
  font-size: 16px;
  font-weight: 400;
}

.stock_name {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}

.stock_comp_name {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}

.screenerdetails-table td {
  font-size: 16px;
}


/* sock details css */

.updatedstock-name {
  font-size: 32px;
  line-height: normal;
}

.stock-detail-tables {
  background: var(--card-background);
}

.buy-sell-btn-stockdetails {
  display: flex;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #FFFFFF;
  background-color: #19AF55;
  font-weight: 400;
  cursor: pointer;
}

.sell-btn-stockdetails {
  background-color: #FF420E !important;
}

.stockdeatail-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}

.second-stockdetails-card {
  justify-self: center;
}

.third-stockdetails-card {
  justify-self: end;
}

.tables-instockdetail {
  background: var(--card-background);
  border-radius: 0.375rem;
}

.stockdetail-card {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background: var(--card-background);
  border-radius: 0.375rem;
  width: 100%;
}

.stockdetail-card-name {
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
}

.stockdetail-card-desc {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 0.375rem;
  font-weight: 400;
}

.stockdetail-scanner-name {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.25rem;
}

.scanner-timeframe {
  font-size: .875rem;
  line-height: 1.25rem;
  background: var(--btn-bg);
  color: var(--primary);
  padding: 0.125rem 0.5rem;
  width: 4rem;
  border-radius: 0.375rem;
  margin-right: 6px;
}

.stockdetail-card-tag {
  font-weight: 500;
  font-size: 14px;
  padding: 0.125rem 0.5rem;
}

.s1-r1 {
  font-size: 14px;
}

.circle-dot {
  width: 1.25em;
  height: 1.25rem;
}

.stick-height {
  height: 4rem;
}

.stick-width {
  width: 0.375rem;
}

.tech-indicator-table {
  margin-top: 24px;
}

.tech-indicator-table td {
  color: var(--text-color);
}

.border-bottom-stock-detail {
  border-bottom: var(--box-border);
}

.tech-indicator-table th {
  color: var(--secondary-text);
  font-size: 16px;
  font-weight: 400;
}

.data-indicator {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}

.data-fullname {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}

.pivot-level-table {
  margin-top: 36px;
}

.pivot-level-table th {
  color: var(--primary);
  font-size: 14px;
  font-weight: 400;
}

.pivot-level-table thead {
  border: 1px solid var(--box-border);
  background: var(--header-bg);
}

.pivot-level-table td {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}


/* Stock search modal css */
.stock-search-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

}

.stock-search-modal-container .modal-content {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 8px;
  height: 330px;
  width: 322px;
}

.stock-search-modal-container .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stock-search-modal-container .search-input {
  border: 1px solid var(--box-border);
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  margin-right: 10px;
}

.stock-search-modal-container .close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.stock-search-modal-container .popular-searches {
  color: var(--secondary-text);
  font-size: 14px;
  padding: 8px 0;
}

.stock-search-modal-container .stock-list {
  overflow-y: auto;
  max-height: 233px;
  list-style: none;
  padding: 0;
}

.stock-search-modal-container .stock-item {
  cursor: pointer;
  padding: 6px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock-search-modal-container .stock-name {
  font-size: 16px;
  font-weight: 500;
}

.stock-search-modal-container .stock-info {
  background-color: var(--box-border);
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
}

.stock-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;
  top: 3.75rem;
  position: sticky;
  z-index: 20;
  background-color: var(--background);
  padding-top: 1rem;
}

.pivot-level-table {
  margin-bottom: 6rem;
}

.hidden-text {
  color: var(--card-background);
}

.optionButtonInStockDetails {
  display: flex;
  border: 1px solid var(--primary);
  border-radius: 0.375rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  color: var(--primary);
  background-color: var(--card-background);
  font-weight: 500;
  cursor: pointer;
}

.disabled-optionButtonInStockDetails {
  display: flex;
  border: 1px solid #EFEFEF;
  background: #C2C2C2;
  color: #636363;
  cursor: not-allowed;
  border-radius: 0.375rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
}

/* =========================ChatBOT CSS====================== */
.icon-button {
  position: fixed;
  bottom: 50px;
  right: 20px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  background: none;
}

.chatbot-container {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 395px;
  height: 630px;
  border: none;
  z-index: 999;
  overflow: hidden;
  border-radius: 10px;
  background-color: none;
}

.chatbot-iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
}

.chatbot-logo {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

@media (max-width: 900px) {
  .card-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

/* Media query for screens with a width of 412px or less */
@media (max-width: 430px) {
  .chatbot-container {
    height: 98vh;
    width: 98vw;
    bottom: 0px;
    right: 0px;
  }

  .icon-button {
    bottom: 65px;
  }

  .mobile-tooltip {
    max-width: 320px !important;
  }

  .mobile-header {

    display: block;
  }


  .desktop-header {
    display: none;
  }

  .mobile-screen {
    display: block !important;
  }

  .desktop-screen {
    display: none !important;
  }

  .desktop-screenn {
    display: none !important;
  }

  .header {
    margin-top: 14px;
    background: white;
    z-index: 999;
  }

  .symbol-name {
    color: #393939;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;

  }

  .symbol-selection {
    min-width: 0;
    height: auto;
    width: auto;
  }

  .sym-div {
    flex-direction: column;
    align-items: start !important;
    gap: 4px;
  }

  .custom-range-container {
    width: 100%;
  }

  .custom-range {
    width: 100%;
  }

  .option-chain-table-container {
    grid-column: span 4;
  }

  .option-chain-table tr {
    height: 40px;
  }

  /* Strategy creator page */
  .creator-bottom {
    height: 41px;
  }


  .strategy-menu {
    height: auto;
    padding: 1rem;
    gap: 0.5rem;
  }

  .pos-menu-container {
    height: auto;
    padding: 12px 16px;
  }

  .table-container {
    overflow-x: hidden;
  }

  .create-strategy {
    width: auto;
  }

  .highcharts-background {
    y: -16px;
  }

  .strategy-instruments {
    height: auto;
    margin-bottom: 20px;
  }

  .risk-factor {
    width: auto;
  }

  .table-cell {
    padding: 0px;
  }

  .watchlist {
    height: 70vh;
  }

  .table-first-container {
    height: auto;
    width: -webkit-fill-available;
    overflow-y: initial;
  }

  .confirm-popup-toast {
    width: 350px;
    left: 30px;
  }

  .confirm-popup-toast .Toastify__toast-body {
    padding: 0px 0px 0px 12px;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  .custom-success-toast {
    background-color: #CDECDA;
    width: 336px;
    right: 44px;
    top: 100px;
  }

  .custom-error-toast {
    width: 336px;
    right: 44px;
    top: 100px;
  }

  .base-toast p {
    font-size: 12px !important;
  }

  .symbol-info .symbol-name {
    font-size: 18px;
  }

  .symbol-info .lp {
    font-size: 20px;
  }

  .symbol-info .ch-chp {
    font-size: 16px;
  }

  .symbol-most-traded .most-traded {
    font-size: 12px;
  }

  .symbol-most-traded .most-traded-title {
    font-size: 12px;
  }

  .symbol-name {
    margin-bottom: -12px;
  }

  .expiry-date-title {
    font-size: 12px;
  }

  .symbol-date .date {
    font-size: 12px;
    width: 88px;
  }

  .option-chain-table td {
    font-size: 12px;
  }

  .table-row-2 {
    background-color: #2A2D33;

  }

  .option-chain-table .table-header-columns {
    font-size: 12px;
    border-right: 1px solid #DEE2E6;
  }

  .hovered-row {
    background-color: green;
  }

  td.strike {
    margin: 0;
    padding: 9.85px;
  }


  .search-modal .modal-content {
    border-radius: 8px !important;
    /* margin-top: 115rem; */
    max-height: 125rem
  }

  .search-modal .modal-dialog {
    width: 336px;
    height: auto;
    position: absolute;
    left: auto;
    /* min-height: 33rem; */
    margin-left: 36px;
    margin-top: 5rem;
  }

  .search-modal .modal-body {
    max-height: 30rem;
    overflow-y: scroll;
  }



  .basket-order-btn {
    height: 52px;
    width: auto;
    border-radius: 4px;
  }

  .screener-modal .modal-content {
    width: 360px;
  }

  .screnner-modal-dropdown .btn {
    height: 24px;
  }

  .ce {
    width: 24px;
    height: 24px;
  }

  .strategy-lots {
    width: 71px;
  }

  .screener-modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
  }

  .create-strategy-btn {
    background: var(--btn-bg);
    width: auto;
    border: none;
    color: var(--text-color);
  }

  .strategy-graph {
    width: 100% !important;
  }

  .main-graph-table {
    width: auto !important;
    max-width: 100%;
  }

  .price,
  .btn-date,
  .strategy-lots,
  .strategy-strike {
    height: 24px;
  }

  .expiry-dropdown-screener {
    width: 81px !important;
  }

  .pos-left {
    width: auto;
    height: 148px;
  }

  .pos-strategy-menu {
    gap: 110px !important;
  }

  .limit-banner {
    width: auto !important;
  }

  .analyse-screener-page {
    grid-column: span 4;
  }

  .analyse-card {
    width: -webkit-fill-available;
  }

  .back-btn-margin {
    margin-left: 0;
  }

  .pattern-image {
    width: 85px;
    height: 85px;
  }

  .analyse-card:last-child {
    margin-bottom: 4rem;
  }

  .screenerdata-desc {
    font-size: 14px;
  }

  .last-updated {
    font-size: 14px;
  }

  .stocks-found {
    font-size: 14px;
  }

  .screenerdetails-stock-info-div,
  .timeandgroup,
  .searh-download-share {
    width: -webkit-fill-available;
  }

  .download-share-btn-mob {
    height: 22px !important;
  }

  .card-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .search-and-filter {
    margin-top: 12px;
  }

  .screenerdata-heading {
    font-size: 24px;
  }

  .stockdeatail-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .third-stockdetails-card {
    justify-self: center;
  }

  .stockdetail-card {
    width: auto;
    height: auto;
  }

  .stock-header {
    display: block;
    position: sticky;
    z-index: 20;
    background-color: var(--background);
    padding-top: 1rem;
    top: 0;
  }

  .stockdetail-toggle-icon {
    position: absolute;
    right: 1.75rem;
  }

  .sticky-column-header {
    position: sticky;
    left: 0;
    z-index: 1;
    background: var(--header-bg);
  }

  .sticky-column-body {
    position: sticky;
    left: 0;
    background: var(--card-background);
  }

  .tech-indicator-table tbody tr {

    border-bottom: 1px solid var(--box-border);
  }

  .updatedstock-name {
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  .custom-success-toast {
    right: 29px;
  }

  .custom-error-toast {
    right: 29px;
  }

  .search-modal .modal-dialog {
    margin-left: 26px;
  }


}

@media (max-width: 389px) {
  .confirm-popup-toast {
    left: 11px;
  }

  .search-modal .modal-dialog {
    margin-left: 20px;
  }

}

@media (max-width: 391px) {
  .custom-success-toast {
    right: 21px;
  }

  .custom-error-toast {
    right: 21px;
  }

}

@media (max-width: 361px) {
  .confirm-popup-toast {
    left: 4px;
  }

  .custom-success-toast {
    right: 12px;
  }

  .custom-error-toast {
    right: 12px;
  }
}


.parallax-table {
  display: flex;
  overflow: hidden;
  position: relative;
}

.left-side,
.center-column,
.right-side {
  width: 100%;
  height: 100%;
  display: inline-block;
}



table.table.table-responsive.left-side tr>td:nth-last-child(2) {
  position: sticky;
  right: 56px;
}

table.table.table-responsive.left-side tr>th:nth-last-child(2) {
  position: sticky;
  right: 56px;
}

table.table.table-responsive.left-side tr>td:last-child {
  position: sticky;
  right: 0;
}

table.table.table-responsive.left-side tr>th:last-child {
  position: sticky;
  right: 0;
}


/* @media screen and (min-width: 400px) and (max-width: 450px) {
  .adjustment-btn {
    visibility: visible;
  }
} */


/*stock details*/
.stockPage {
  /* background-color:#FBFBFB; */
}

.min-h-600 {
  min-height: 600px;
  width: 100%vw;
}

.min-h-650 {
  min-height: 650px;
  width: 100%vw;
}