.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: background-color 0.5s;
}

.react-modal-children {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.react-modal-wrapper {
  position: fixed;
  top: 37%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.react-modal-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: auto;
  background-color: #f9ebd3;
  background-clip: padding-box;
  padding: 0.5rem;
}

.text-children {
  width: 80%;
  font-size: 16px;
  font-weight: 500;
}

.close-btn {
  position: absolute;
  top: 2px;
  right: 11px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  padding: 0;
  margin: 0;
}

.close-btn:hover {
  color: #999;
}

.excla {
  padding-top: 2px;
  font-size: 25px;
  color: rgb(160, 157, 157);
}

.icon-cross {
  font-size: 25px;
  margin: 0;
  top: 0;
  right: 0;
  position: absolute;
}

.icon-cross-div {
  position: relative;
}

.span-bold {
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .react-modal-content {
    width: 480px;
  }
}

/* ****************RiskDisclouser***************** */
@media only screen and (max-width: 700px) {
  .risk-container {
    display: flex;
    width: 85vw;
    /* height: 351px; */

    border-radius: 6px;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    margin: auto;
    margin-top: auto;
    opacity: 1;
    background-color: white;
    z-index: 1001;
  }
}
@media only screen and (min-width: 700px) {
  .risk-container {
    display: flex;
    width: 642px;
    height: 351px;

    border-radius: 6px;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    margin: auto;
    margin-top: auto;
    opacity: 1;
    background-color: white;
    z-index: 1001;
  }
}

.risk-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
}

.risk-ul-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  text-align: left;
}

.proceed-button-risk {
  width: 112px;
  height: 31px;
  margin: auto;
  background: #383fec;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  cursor: pointer;
}

.risk-a-link {
  display: flex;
  align-items: left;
  text-decoration-color: "#3575D6";
}

.fade-out {
  opacity: 0;
  transition: opacity 50s ease-out; /* Adjust timing and easing as needed */
}
